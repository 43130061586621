.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ddd;

  h1 {
    font-size: 16px;
    margin: 0;
    color: #555;
  }
}

.pop {
  background-color: #fff;
  width: 100px;
  box-shadow: 0 0 30px 0 rgb(51 51 51 / 20%);
  margin-top: 12px;
  margin-right: 4px;
  border-radius: 4px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 4px 0;

    li {
      padding: 6px 0;
      text-align: center;
      border-bottom: 1px solid #efefef;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.more {
  width: 14px;
  height: 14px;
  opacity: 0.5;
}
