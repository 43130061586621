body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6f7;
}

a {
  outline: none;
}

.list .index-1,
.list .index-2,
.list .index-3 {
  color: #f26d5f;
  font-weight: bold;
  font-size: 16px;
}

.icon-top {
  width: 3em;
  height: 3em;
  opacity: 0.5;
}

.icon-more {
  color: #666;
}
